import EnhancedDisplay from '@/components/enhancedDisplay';
import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import StyledImage from '@/components/styledImage';
import { mutateGraphQL } from '@/data/apollo';
import { axiosClient } from '@/data/axios';
import {
	PurchaseWrite_PurchaseForVendorMutation,
	PurchaseWrite_PurchaseForVendorMutationVariables,
} from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import idPick from '@/helpers/idPick';
import { calculateProfitPercentage, NewLineItem } from '@/modals/purchaseReceiving/updateReceiving';
import { syncItemToClover } from '@/pages/dashboard/management/items/itemUtils';
import VendorSelect from '@/pages/formSelects/vendorSelect';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { ItemValidator, LineItem, Location, Menu, MutationItemsBatchWriteArgs } from '@/types/schema';
import wait from '@/utils/wait';
import { gql } from '@apollo/client';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import Bluebird from 'bluebird';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function PurchaseForVendor( { lineItems, location, goToPreviousStep }: {
	lineItems: NewLineItem[],
	location: Location,
	goToPreviousStep: () => void
} ) {
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const { closeModal } = useModalControls();
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	
	const [ progress, setProgress ] = useState( 0 );
	const showSnackAndRedirect = async ( purchaseId: string ) => {
		enqueueSnackbar( 'Purchase created successfully', { variant: 'success' } );
		closeModal();
		await router.push( `/dashboard/commerce/purchases/${purchaseId}` );
	};
	
	return (
		<Form<{ vendor: Menu | null }>
			initialValues={{ vendor: null }}
			onSubmit={async ( values ) => {
				const items: Array<ItemValidator> = lineItems
					.map( ( { item } ) => ( {
						...idPick( item, [ 'name', 'taxable' ] ),
						image    : item?.image || undefined,
						locations: [ location.id ],
						uoms     : item?.uoms?.map( ( uom ) => ( {
							...idPick( uom, [ 'cost', 'price', 'sku', 'name', 'quantity', 'selected' ] ),
						} ) ),
						categories: undefined,
					} ) as any );
				
				await mutateGraphQL<MutationItemsBatchWriteArgs>( {
					mutation: gql`
						mutation ItemsBatchWrite_PurchaseReceiving($inputs: [ItemValidator!]!, $ids:   [String!]) {
							itemsBatchWrite(inputs: $inputs, ids: $ids) {
								id
							}
						}
					`,
					variables: {
						// @ts-expect-error: will remove item.new
						ids   : lineItems.filter( ( { item } ) => !item?.new ).map( ( { item } ) => item?.id ),
						inputs: items,
					},
				} );
				
				if ( location?.gateway?.external === 'CLOVER' ) {
					// sync items to clover
					await Bluebird.map(
						lineItems,
						( { item } ) => Promise.all(
							[ syncItemToClover( { itemId: item!.id as string, locationId: location.id, postItemOnly: true } ),
								wait( 150 ) ],
						),
						{ concurrency: 2 },
					);
				}
				
				const { purchaseWrite } = await mutateGraphQL<PurchaseWrite_PurchaseForVendorMutationVariables, PurchaseWrite_PurchaseForVendorMutation>( {
					mutation: gql`
						mutation PurchaseWrite_PurchaseForVendor($input: PurchaseValidator, $method: String, $customNumber: Boolean) {
							purchaseWrite(input: $input, method: $method, customNumber: $customNumber) {
								id
							}
						}
					`,
					variables: {
						customNumber: Boolean( staff?.company.metadata?.customPurchaseNumber ),
						method      : 'New Purchase',
						input       : {
							staff          : staff?.id,
							companyLocation: location?.id || null,
							serviceDate    : new Date(),
							menu           : values.vendor?.id || null,
							lineItems      : lineItems.map( ( lineItem ) => ( {
								...idPick( lineItem, [
									'name',
									'price',
									'cost',
									'image',
									'description',
									'unit',
									'externalId',
									'metadata',
								] ),
								receivedQuantity: 0,
								quantity        : lineItem.receivedQuantity,
								code            : lineItem.code ?? undefined,
								uom             : lineItem.uom?.id ?? undefined,
								item            : lineItem.item?.id ?? undefined,
							} ) ),
						},
					},
				} );
				
				if ( purchaseWrite ) await axiosClient.post( '/api/user/inventory/autoStockPurchase', { id: purchaseWrite.id } );
				
			}}>
			{( formik ) => (
				<Stack>
					<Stack
						component={Paper}
						direction='row'
						alignItems='end'
						spacing={2}
						my={1}
						width={{ xs: '100%' }}
						sx={{ p: 1 }}>
						<VendorSelect
							sx={{ width: 300 }}
							name='vendor'
							variables={{ options: { limit: 50, filter: { active: true } } }}
							onAdd={undefined}
						/>
					</Stack>
					<EnhancedDisplay
						hasPagination
						selectable
						title={`${t( 'common:item' )}`}
						extraData={lineItems}
						data={lineItems as LineItem[]}
						pageSectionProps={{
							hide                  : true,
							primaryTypographyProps: { variant: 'h5' },
							boxProps              : { sx: { '.MuiListItem-root': { mb: 0, pt: 0 } } },
						}}
						emptyComponent={(
							<Typography
								textAlign='center'
								color='text.secondary'
								py={1}>
								{t( 'common:nothing-to-display' )}
							</Typography>
						)}
						listProps={{
							renderRow: ( item ) => {
								const selectedUom = item?.uom;
								return (
									<Stack direction='row' spacing={1}>
										<Box textAlign='left'>
											<Stack>
												<Typography key='name'>
													{`${item.name} (${selectedUom?.name})`}
												</Typography>
												<Typography key='code'>
													{selectedUom?.code || '-'}
												</Typography>
												<Typography key='sku'>
													{selectedUom?.sku || '-'}
												</Typography>
											</Stack>
										</Box>
										<Box textAlign='right'>
											<Stack spacing={1}>
												<Stack
													direction='row'
													spacing={1}
													alignItems='right'
													sx={{ alignSelf: 'end', alignItems: 'center', justifyContent: 'end' }}>
													<Typography key='cost'>
														{currencyFormat( item.cost || selectedUom?.cost || 0 )}
													</Typography>
													<Typography key='price'>
														{currencyFormat( item.price || selectedUom?.price || 0 )}
													</Typography>
													<Typography key='profit'>
														{calculateProfitPercentage( item.cost || selectedUom?.cost || 0, item.price || selectedUom?.price || 0 )
															.toFixed( 2 )
															.replace( /\.00$/, '' )}%
													</Typography>
												</Stack>
												<Stack direction='row' spacing={1}>
													<Typography key='quantity'>
														{item.quantity || '-'}
													</Typography>
													<Typography key='stock'>
														{selectedUom?.quantity || '-'}
													</Typography>
													<Typography key='received'>
														{item.receivedQuantity || '-'}
													</Typography>
												</Stack>
											</Stack>
										</Box>
									
									</Stack>
								);
							},
						}}
						tableProps={{
							hover  : false,
							headers: [ 'Name',
								'Unit',
								'Code',
								'Sku',
								'Cost',
								'Price',
								'Profit',
								'LineItem Quantity',
								'Quantity On Hand',
								'Quantity to Add' ],
							columns: ( item ) => {
								const selectedUom = item?.uom;
								return [
									<Typography key='name'>
										{item.name}
									</Typography>,
									<Typography key='unit'>
										{selectedUom?.name}
									</Typography>,
									<Typography key='code'>
										{item?.code || selectedUom?.code || '-'}
									</Typography>,
									<Typography key='sku'>
										{selectedUom?.sku || '-'}
									</Typography>,
									<Typography key='cost'>
										{currencyFormat( item?.cost || selectedUom?.cost || 0 )}
									</Typography>,
									<Typography key='price'>
										{currencyFormat( item?.price || selectedUom?.price || 0 )}
									</Typography>,
									<Typography key='profit'>
										{calculateProfitPercentage( item.cost || selectedUom?.cost || 0, item.price || selectedUom?.price || 0 )
											.toFixed( 2 )
											.replace( /\.00$/, '' )}%
									</Typography>,
									<Typography key='quantity'>
										{item?.quantity || '-'}
									</Typography>,
									<Typography key='quantityOnHand'>
										{selectedUom?.quantity || '-'}
									</Typography>,
									<Typography key='quantityToAdd'>
										{item?.receivedQuantity || '-'}
									</Typography>,
								];
							},
							cellProps: [
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
								{ align: 'left' },
							],
						}}
					/>
					<Stack sx={{ mt: 1 }} direction='row' spacing={1}>
						<Button
							variant='outlined'
							onClick={() => goToPreviousStep()}>
							Back
						</Button>
						<AsyncLoadingButton
							variant='contained'
							color='primary'
							progress={progress}
							disabled={lineItems.length === 0}
							loading={formik.isSubmitting}
							onClick={async () => await formik.submitForm()}>
							<StyledImage
								alt='clover-pos-image'
								src='/images/clover-icon.png'
								width='20px'
								height='20px'
								sx={{ mr: 1 }}
							/> Receive and update stocks
						</AsyncLoadingButton>
					</Stack>
				</Stack>
			)}
		</Form>
	);
	
}
