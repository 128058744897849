import Form from '@/components/form';
import FormattedTextField from '@/components/formattedTextField';
import Loading from '@/components/loading';
import SearchBar from '@/components/searchBar';
import { useInfiniteGraphQL } from '@/data';
import { ItemsRead } from '@/data/management/item.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { createLineItemThroughItem } from '@/pages/dashboard/commerce/form/lineItem/lineItemUtils';
import CategorySelect from '@/pages/formSelects/categorySelect';
import VendorSelect from '@/pages/formSelects/vendorSelect';
import { Category, Gateway, Item, LineItem, Menu, type Uom } from '@/types/schema';
import {
	Box,
	Button,
	Grid,
	MenuItem,
	Paper,
	Select,
	Stack,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { isEmpty, isNumber, set, throttle } from 'lodash-es';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { useDebouncedValue } from 'rooks';
import { v4 as uuidv4 } from 'uuid';

export type NewLineItem = Partial<LineItem> & { new: boolean };
export type NewItem = Partial<Item> & { new: boolean };

export const calculateProfitPercentage = ( cost: number, price: number ) => {
	if ( cost === 0 && price === 0 || cost - price === 0 ) return 0;
	return ( price - cost ) / ( price || 1 ) * 100;
};

const orderByOptions = [
	{ field: 'createdAt', order: 'ASC', label: 'Created Time (ASC)' },
	{ field: 'createdAt', order: 'DESC', label: 'Created Time (DESC)' },
	{ field: 'name', order: 'ASC', label: 'Name (A-Z)' },
	{ field: 'name', order: 'DESC', label: 'Name (Z-A)' },
];

export default function UpdateReceiving( {
	gateway,
	lineItems,
	setLineItems,
}: {
	gateway: Gateway,
	lineItems: NewLineItem[],
	setLineItems: ( lineItems: NewLineItem[] ) => void
} ) {
	const [ search, setSearch ] = useState( '' );
	const [ itemsInputState, setItemsInputState ] = useState( {} );
	const [ newItems, setNewItems ] = useState<NewItem[]>( [] );
	const [ category, setCategory ] = useState<Category | null>( null );
	const [ vendor, setVendor ] = useState<Menu | null>( null );
	const [ purchaseLineItems, setPurchaseLineItems ] = useState<NewLineItem[]>( lineItems || [] );
	const [ orderByOption, setOrderByOption ] = useState( { label: 'Created Time (DESC)', field: 'createdAt:DESC' } );
	const [ debouncedSearch ] = useDebouncedValue<string | ''>( search, 700 );
	
	const {
		flattenedData,
		isLoading,
		isFetching,
		hasNextPage,
		fetchNextPage,
		count,
	} = useInfiniteGraphQL<
		{ options: { limit: number; filter: any; orderBy: any[] } },
		{ id: string; items: Item[] }
	>( {
		query    : ItemsRead,
		queryKey : [ 'item' ],
		variables: {
			options: {
				limit : 50,
				filter: {
					gateway   : gateway?.id,
					isHidden  : null,
					categories: category ? category.id : undefined,
					uoms      : { selected: true, ...vendor ? { menus: [ vendor.id ] } : {} },
					...debouncedSearch?.length && {
						$or: [
							'name',
							'uoms.name',
							'uoms.sku',
						].map( ( field ) => set( {}, field, { $ilike: debouncedSearch } ) ),
					},
				},
				orderBy: [ orderByOption.field ],
			},
		},
	}, { enabled: Boolean( orderByOption.field ) } );
	
	const buildInputData = ( item: any ) => ( {
		name            : item.name,
		unit            : item.uoms[ 0 ].name,
		sku             : item.uoms[ 0 ].sku,
		cost            : item.uoms[ 0 ].cost,
		price           : item.uoms[ 0 ].price,
		quantity        : item.uoms[ 0 ].quantity,
		sequence        : item.uoms[ 0 ].sequence || 5,
		receivedQuantity: '',
	} );
	
	const handleChange = throttle( async (
		item: NewItem,
		selectedUom: Uom,
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		type: 'receivedQuantity' | 'name' | 'unit' | 'code' | 'sku' | 'cost' | 'price',
	) => {
		if ( !selectedUom || !item?.id ) return;
		
		const input = e.target.value.trim() === '' ? '' : e.target.value;
		
		const updateItemsInputState = {
			...itemsInputState,
			[ item.id ]: {
				...itemsInputState[ item.id ] || {},
				[ type ]: [ 'receivedQuantity', 'cost', 'price' ].includes( type ) ? Math.abs( +input ) : input,
			},
		};
		
		setItemsInputState( updateItemsInputState );
		
		const itemsToUpdate = item?.new ? newItems : flattenedData;
		
		const updatedItemIndex = itemsToUpdate.findIndex( ( i ) => i.id === item.id );
		const changedItem = itemsToUpdate[ updatedItemIndex ];
		const changedUom = changedItem.uoms?.[ 0 ];
		
		const updatedItem = {
			...itemsToUpdate[ updatedItemIndex ],
			name: updateItemsInputState[ item.id ]?.name,
			uoms: [ {
				...changedUom,
				name    : updateItemsInputState[ item.id ]?.unit,
				code    : updateItemsInputState[ item.id ]?.code,
				sku     : updateItemsInputState[ item.id ]?.sku,
				cost    : updateItemsInputState[ item.id ]?.cost,
				price   : updateItemsInputState[ item.id ]?.price,
				quantity: updateItemsInputState[ item.id ]?.quantity,
				sequence: updateItemsInputState[ item.id ]?.sequence || 5,
			} as Partial<Uom> ],
		} as NewItem;
		
		if ( item?.new ) {
			const newUpdatedItems = [ ...itemsToUpdate ];
			newUpdatedItems[ updatedItemIndex ] = updatedItem;
			setNewItems( newUpdatedItems );
		}
		
		// if no name or received quantity, remove line item
		if ( [ 'name', 'receivedQuantity' ].includes( type ) && input === '' ) {
			const newLineItems = purchaseLineItems.filter( ( lineItem ) => lineItem.uom?.id !== selectedUom.id );
			setPurchaseLineItems( newLineItems );
			return;
		}
		
		const hasName = type === 'receivedQuantity' && updateItemsInputState[ item.id ]?.name;
		const hasReceivedQuantity = type === 'name' && isNumber( updateItemsInputState[ item.id ]?.receivedQuantity );
		const selectedPurchaseLineItem = purchaseLineItems.find( ( lineItem ) => lineItem?.item?.id === item?.id );
		
		// if name or received quantity, create/update line item
		if ( hasName || hasReceivedQuantity || selectedPurchaseLineItem ) {
			const lineItem = await createLineItemThroughItem( {
				selectedItem    : updatedItem,
				selectedUom     : updatedItem?.uoms?.[ 0 ],
				receivedQuantity: type === 'receivedQuantity' ? +input : updateItemsInputState[ item.id ]?.receivedQuantity,
			} );
			
			if ( selectedPurchaseLineItem ) {
				const newLineItems = purchaseLineItems.filter( ( lineItem ) => lineItem?.item?.id !== item?.id );
				setPurchaseLineItems( [ ...newLineItems, lineItem as NewLineItem ] );
			} else {
				setPurchaseLineItems( [ ...purchaseLineItems, lineItem as NewLineItem ] );
			}
		}
		
	}, 300 );
	
	useEffect( () => {
		setLineItems( purchaseLineItems );
	}, [ purchaseLineItems ] );
	
	// when flattenedData changes set it into setItemsInputState
	useEffect( () => {
		
		if ( isEmpty( flattenedData ) ) return;
		
		const existingData = flattenedData?.reduce( ( obj, item ) => {
			obj[ item.id ] = buildInputData( item );
			return obj;
		}, {} );
		setItemsInputState( ( prev ) => ( {
			...prev,
			...Object.keys( existingData ).reduce( ( obj, key ) => {
				if ( !prev[ key ] ) {
					obj[ key ] = existingData[ key ];
				}
				return obj;
			}, {} ),
		} ) );
	}, [ flattenedData ] );
	
	// if ( !flattenedData && ( isLoading || isFetching ) || Boolean( search ) && isLoading ) return <Loading/>;
	
	return (
		<Paper sx={{ bgcolor: 'background.default', p: 1, height: 600, overflow: 'hidden' }}>
			<Form initialValues={{ category, vendor }} onSubmit={() => {}}>
				<Fragment>
					<Grid
						container
						alignItems='end'
						spacing={2}
						my={1}
						width={{ xs: '100%' }}>
						<Grid item xs={12} sm={4}>
							<SearchBar
								fullWidth
								size='small'
								placeholder='Search to see more items'
								setSearch={setSearch}
								searchText={search}
								label='Search'
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<CategorySelect
								fullWidth
								name='category'
								type='ITEM'
								onAdd={undefined}
								onChange={async ( e, newValue: Menu ) => setCategory( newValue )}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<VendorSelect
								fullWidth
								name='vendor'
								variables={{ options: { limit: 50, filter: { active: true } } }}
								onAdd={undefined}
								onChange={async ( e, newValue: Menu ) => setVendor( newValue )}
							/>
						</Grid>
					</Grid>
				</Fragment>
			</Form>
			<Stack direction='row' justifyContent='space-between' spacing={1} sx={{ my: 2 }}>
				<Select
					sx={{ width: { sm: '30%', xs: '70%' } }}
					variant='outlined'
					value={orderByOption.label}
					label='Sort By'
					onChange={( e ) => {
						const selectedOption = orderByOptions.find( ( option ) => option.label === e.target.value );
						if ( selectedOption ) {
							setOrderByOption( {
								label: selectedOption.label,
								field: `${selectedOption.field}:${selectedOption.order}`,
							} );
						}
					}}>
					{orderByOptions.map( ( option ) => (
						<MenuItem key={option.label} value={option.label}>
							{option.label}
						</MenuItem>
					) )}
				</Select>
				<Button
					onClick={() => {
						
						const newItem = {
							id   : uuidv4(),
							name : '',
							image: '',
							new  : true,
							uoms : [ {
								id      : uuidv4(),
								name    : 'Unit',
								code    : '',
								removed : false,
								sku     : '',
								cost    : 0,
								price   : 0,
								quantity: 0,
								selected: true,
								sequence: 5,
							} as Partial<Uom> ],
						};
						
						setNewItems( [ newItem as NewItem, ...newItems ] );
						setItemsInputState( ( prev ) => ( {
							...prev,
							[ `${newItem.id}` ]: buildInputData( newItem ),
						} ) );
					}}>
					Add New Item
				</Button>
			</Stack>
			{isFetching ? <Loading/> : !hasNextPage && count > 10 ? (
				<Typography sx={{ textAlign: 'center', color: 'text.secondary', mt: 2 }}>
					No more results
				</Typography>
			) : ( <TableContainer
				sx={{
					'borderRadius'      : 2,
					'borderColor'       : 'divider',
					'overflow'          : 'hidden',
					'minHeight'         : 500,
					'height'            : 500,
					'.MuiTableCell-root': { padding: 1, minWidth: 140 },
				}}>
				<TableVirtuoso
					totalCount={[ ...newItems, ...flattenedData ].length}
					data={[ ...newItems, ...flattenedData ]}
					itemContent={( index, item ) => {
						const selectedUom = item?.uoms?.find( ( uom ) => uom.selected );
						
						return (
							<Fragment key={index}>
								<TableCell sx={{ width: 200, bgcolor: item?.new ? 'alpha.success' : null }}>
									{item?.new ? (
										<FormattedTextField
											fullWidth
											sx={{ width: 200 }}
											value={itemsInputState[ item.id ]?.name || ''}
											onChange={( e ) => handleChange( item, selectedUom, e, 'name' )}
											onFocus={( e ) => e.target.select()}
										/>
									) : (
										<Typography sx={{ minWidth: 200 }} width={120}>{item.name || ''}</Typography>
									)}
								</TableCell>
								<TableCell width={120} sx={{ bgcolor: item?.new ? 'alpha.success' : null }}>
									{item?.new ? (
										<FormattedTextField
											fullWidth
											sx={{ width: 120 }}
											value={itemsInputState[ item.id ]?.unit || ''}
											onChange={( e ) => handleChange( item, selectedUom, e, 'unit' )}
											onFocus={( e ) => e.target.select()}
										/>
									) : (
										<Typography>{selectedUom?.name || '-'}</Typography>
									)}
								</TableCell>
								<TableCell width={120} sx={{ bgcolor: item?.new ? 'alpha.success' : null }}>
									{item?.new ? (
										<FormattedTextField
											fullWidth
											sx={{ width: 120 }}
											value={itemsInputState[ item.id ]?.code || ''}
											onChange={( e ) => handleChange( item, selectedUom, e, 'code' )}
											onFocus={( e ) => e.target.select()}
										/>
									) : (
										<Typography>{selectedUom?.code || '-'}</Typography>
									)}
								</TableCell>
								<TableCell width={120} sx={{ bgcolor: item?.new ? 'alpha.success' : null }}>
									{item?.new ? (
										<FormattedTextField
											fullWidth
											sx={{ width: 120 }}
											value={itemsInputState[ item.id ]?.sku || ''}
											onChange={( e ) => handleChange( item, selectedUom, e, 'sku' )}
											onFocus={( e ) => e.target.select()}
										/>
									) : (
										<Typography>{selectedUom?.sku || '-'}</Typography>
									)}
								</TableCell>
								<TableCell width={120} sx={{ bgcolor: item?.new ? 'alpha.success' : null }}>
									{itemsInputState[ item.id ]?.receivedQuantity ? (
										<FormattedTextField
											fullWidth
											sx={{ width: 120 }}
											value={currencyFormat( itemsInputState[ item.id ]?.cost || '' )}
											onChange={( e ) => handleChange( item, selectedUom, e, 'cost' )}
											onFocus={( e ) => e.target.select()}
										/>
									) : (
										<Typography>{itemsInputState[ item.id ]?.cost || '-'}</Typography>
									)}
								</TableCell>
								<TableCell width={120} sx={{ bgcolor: item?.new ? 'alpha.success' : null }}>
									{itemsInputState[ item.id ]?.receivedQuantity ? (
										<FormattedTextField
											fullWidth
											sx={{ width: 120 }}
											value={currencyFormat( itemsInputState[ item.id ]?.price || '' )}
											onChange={( e ) => handleChange( item, selectedUom, e, 'price' )}
											onFocus={( e ) => e.target.select()}
										/> ) : (
										<Typography>{itemsInputState[ item.id ]?.price || '-'}</Typography>
									)}
								</TableCell>
								<TableCell width={120} sx={{ bgcolor: item?.new ? 'alpha.success' : null }}>
									<Typography>
										{`${calculateProfitPercentage( itemsInputState[ item.id ]?.cost || 0, itemsInputState[ item.id ]?.price || 0 )
											.toFixed( 2 )
											.replace( /\.00$/, '' )}%`}
									</Typography>
								</TableCell>
								<TableCell width={120} sx={{ bgcolor: item?.new ? 'alpha.success' : null }}>
									{item?.new ? (
										<Tooltip title='Nothing on hand'>
											<Box>
												<FormattedTextField
													fullWidth
													disabled
													sx={{ width: 120 }}
													value='-'
												/>
											</Box>
										</Tooltip>
									) : (
										<Typography>{selectedUom?.quantity || '-'}</Typography>
									)}
								</TableCell>
								<TableCell width={120} align='right' sx={{ bgcolor: item?.new ? 'alpha.success' : null }}>
									<FormattedTextField
										fullWidth
										sx={{ width: 120 }}
										type='number'
										value={itemsInputState[ item.id ]?.receivedQuantity ?? ''}
										onChange={( e ) => handleChange( item, selectedUom, e, 'receivedQuantity' )}
										onFocus={( e ) => e.target.select()}
									/>
								</TableCell>
							</Fragment>
						);
					}}
					endReached={async () => {
						if ( hasNextPage && !isFetching ) await fetchNextPage();
					}}
					fixedHeaderContent={() => (
						<TableRow
							sx={{
								bgcolor: ( theme ) => theme.palette.mode === 'dark'
									? '#37383A !important'
									: '#E0E0E0 !important',
							}}>
							<TableCell width={200}>
								<Typography>Name</Typography>
							</TableCell>
							<TableCell width={120}>
								<Typography>Unit</Typography>
							</TableCell>
							<TableCell width={120}>
								<Typography>Code</Typography>
							</TableCell>
							<TableCell width={120}>
								<Typography>SKU</Typography>
							</TableCell>
							<TableCell width={120}>
								<Typography>Cost</Typography>
							</TableCell>
							<TableCell width={120}>
								<Typography>Price</Typography>
							</TableCell>
							<TableCell width={120}>
								<Typography>Profit margin</Typography>
							</TableCell>
							<TableCell width={120}>
								<Typography>Qty on hand</Typography>
							</TableCell>
							<TableCell width={120} align='right'>
								<Typography>Add to Stock</Typography>
							</TableCell>
						</TableRow>
					)}
				/>
			</TableContainer> )}
		</Paper>
	);
}
